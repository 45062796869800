






























import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";

@Component
export default class Suppliers extends Vue {
  public suppliers: any = [];
  public onlyActive: boolean = true;

  private async getSuppliers() {
    this.suppliers = await adminService.getSuppliers(this.onlyActive, null);
  }

  public async mounted() {
    await this.getSuppliers();
  }
}
